export const adjustColor = (hex, factor) => {
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  // Ajuste chaque canal RGB
  r = Math.min(255, Math.max(0, Math.floor(r * factor)));
  g = Math.min(255, Math.max(0, Math.floor(g * factor)));
  b = Math.min(255, Math.max(0, Math.floor(b * factor)));

  return `rgb(${r}, ${g}, ${b})`;
};

export const getSvgUrl = params => {
  const { width, color, opacity, stroke } = params;
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${width}" viewBox="0 0 ${width} ${width}">
	  <rect x="0" y="0" width="100%" height="100%" fill="${color || "#FFF000"}" opacity="${opacity || 1}" stroke="${
      stroke || "#FFF000"
    }" />
	</svg>`;
  return URL.createObjectURL(new Blob([svg], { type: "image/svg+xml" }));
};

export const createSVGIcon = (google, text, color, strokeColor) => {
  return {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
		  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
		      <circle cx="25" cy="25" r="20" fill="${color}" stroke="${strokeColor}" stroke-width="2"/>
		      <text x="50%" y="50%" font-size="16" font-family="Arial" font-weight="bold"
			  fill="white" text-anchor="middle" dominant-baseline="middle">${text}</text>
		  </svg>
	      `)}`,
    scaledSize: new google.maps.Size(40, 40),
    anchor: new google.maps.Point(20, 20)
  };
};

export const createSVGAntennaIcon = (google, adjustedColor) => {
  const svg = `
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.576 486.576" width="40" height="40">
	  <g fill="${adjustedColor}">
	    <path d="M130.151,247.487c-62.384-62.384-62.384-163.89,0-226.274L108.938,0c-74.081,74.081-74.081,194.619,0,268.7
	      L130.151,247.487z"/>
	    <path d="M356.425,247.487l21.213,21.213c74.081-74.081,74.081-194.619,0-268.7l-21.213,21.213
	      C418.809,83.597,418.809,185.103,356.425,247.487z"/>
	    <path d="M158.436,219.203l21.213-21.213c-16.967-16.967-26.312-39.568-26.312-63.64s9.344-46.672,26.312-63.64l-21.213-21.213
	      c-22.634,22.633-35.099,52.768-35.099,84.853S135.802,196.569,158.436,219.203z"/>
	    <path d="M328.141,219.203c22.634-22.633,35.099-52.768,35.099-84.853s-12.465-62.219-35.099-84.853L306.928,70.71
	      c16.967,16.967,26.312,39.568,26.312,63.64s-9.344,46.672-26.312,63.64L328.141,219.203z"/>
	    <path d="M262.19,180.629c18.224-7.471,31.099-25.395,31.099-46.279c0-27.57-22.43-50-50-50s-50,22.43-50,50
	      c0,20.883,12.874,38.808,31.098,46.279l-80.258,275.948H85.387v30h315.794v-30H342.44L262.19,180.629z M281.879,355.762
	      l-77.188,0.007l38.597-132.705L281.879,355.762z M243.288,114.35c11.028,0,20,8.972,20,20s-8.972,20-20,20s-20-8.972-20-20
	      S232.26,114.35,243.288,114.35z M195.965,385.77l94.638-0.008l20.594,70.815H175.371L195.965,385.77z"/>
	  </g>
	</svg>
      `;

  return {
    url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svg),
    scaledSize: new google.maps.Size(30, 30),
    anchor: new google.maps.Point(15, 30)
  };
};

export const getMNCColor = mnc => {
  let mncColor = "black";
  switch (mnc) {
    case 1:
      mncColor = "#FF7900"; // Assuming 'orange' is orange
      break;
    case 10:
      mncColor = "#E60000"; // Assuming 'sfr' is red
      break;
    case 20:
      mncColor = "#0085CA"; // Assuming 'bouygues' is blue
      break;
    default:
      mncColor = "black"; // Default color
  }
  return mncColor;
};
