<template>
  <div>
    <div class="box">
      <form @keyup.enter="loadData" @submit.prevent="loadData">
        <b-field grouped>
          <b-field label="Saisir des numéros (ex: D21) de série">
            <b-taginput
              v-model="selectedKarnott"
              :data="filteredKarnott"
              autocomplete
              :allow-new="false"
              :allow-duplicates="false"
              field="serialnumber"
              icon="label"
              placeholder="Saisir un numéro de série"
              @typing="getFilteredSerialNumbers"
              :clear-on-select="true"
              ref="serialNumberInput"
            >
            </b-taginput>
          </b-field>
          <b-field label="Depuis le">
            <b-datetimepicker v-model="fromDate" placeholder="Type or select a date..." icon="calendar-today" editable>
            </b-datetimepicker>
          </b-field>
          <b-field label="Jusqu'au">
            <b-datetimepicker v-model="toDate" placeholder="Type or select a date..." icon="calendar-today" editable>
            </b-datetimepicker>
          </b-field>
          <div class="field button-field">
            <p class="control">
              <button class="button is-success">Mettre à jour</button>
            </p>
          </div>
        </b-field>
        <b-field>
          <p class="control">
            <button
              @click.prevent="
                setTodayDate();
                loadData();
              "
              class="button is-outlined is-dark"
            >
              Aujourd'hui
            </button>
          </p>
          <p class="control">
            <button
              @click.prevent="
                setYesterdayDate();
                loadData();
              "
              class="button is-outlined is-dark"
            >
              Hier
            </button>
          </p>
          <p class="control">
            <button
              @click.prevent="
                setTwoDaysAgoDate();
                loadData();
              "
              class="button is-outlined is-dark"
            >
              12 dernières heures
            </button>
          </p>
          <p class="control">
            <button
              @click.prevent="
                slideDate(24);
                loadData();
              "
              class="button is-outlined is-dark"
            >
              +24 heures
            </button>
          </p>
          <p class="control">
            <button
              @click.prevent="
                slideDate(-24);
                loadData();
              "
              class="button is-outlined is-dark"
            >
              -24 heures
            </button>
          </p>
        </b-field>
      </form>
    </div>
    <div id="map-container">
      <b-loading :is-full-page="false" :active="isLoadingEventPoints"> </b-loading>
      <div id="map-legend" v-if="mapData.length && !device">
        <span
          :style="`color:${darkerColor(r.color)};${r.displayed ? '' : 'text-decoration:line-through'};`"
          v-for="r in mapData"
          :key="r.color"
        >
          <svg width="20px" height="20px" @click="toggleSerialNumber(r.serialNumber)">
            <path d="M 0 0 H 20 V 20 H 0 Z" :fill="r.color" :stroke="darkerColor(r.color)" />
          </svg>
          {{ r.serialNumber }}
        </span>
      </div>
      <div id="map"></div>
    </div>
  </div>
</template>
<script>
/// Below line disables no-undef google Maps related warnings
/// (because Google Maps is loaded in a script tag rather than
/// in the bundle.).
/* global google */
import { SET_ERROR_ACTION } from "Stores/message";
import { GET_LOCATION_BY_DEVICE_INSTANCE_ID } from "Stores/device-data";
import { mapActions } from "vuex";
import { getDevices } from "Api/device";
import { formatInputDates, formatToRFC3339 } from "Utils";
import { drawDataOnMap, darkerColor, markerColors, deviceSessionColor, gpsColors } from "Utils/map";
const getTwoDaysAgoInterval = () => {
  let fromDate = new Date();
  let toDate = new Date();
  fromDate.setHours(fromDate.getHours() - 12);
  return {
    fromDate,
    toDate
  };
};
const getYesterdayInterval = () => {
  let fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - 1);
  fromDate.setHours(0);
  fromDate.setMinutes(0);

  let toDate = new Date();
  toDate.setDate(toDate.getDate() - 1);
  toDate.setHours(23);
  toDate.setMinutes(59);
  return {
    fromDate,
    toDate
  };
};
const getTodayInterval = () => {
  let fromDate = new Date();
  let toDate = new Date();
  fromDate.setHours(0);
  fromDate.setMinutes(0);
  return {
    fromDate,
    toDate
  };
};

export default {
  mounted() {
    this.map = new google.maps.Map(document.getElementById("map"), {
      zoom: 3,
      mapTypeId: google.maps.MapTypeId.HYBRID,
      tilt: 0,
      rotateControl: false
    });
    this.getDevices();
  },
  methods: {
    darkerColor(color) {
      return darkerColor(color);
    },
    setTodayDate() {
      const { fromDate, toDate } = getTodayInterval();
      this.fromDate = fromDate;
      this.toDate = toDate;
    },
    setYesterdayDate() {
      const { fromDate, toDate } = getYesterdayInterval();
      this.fromDate = fromDate;
      this.toDate = toDate;
    },
    setTwoDaysAgoDate() {
      const { fromDate, toDate } = getTwoDaysAgoInterval();
      this.fromDate = fromDate;
      this.toDate = toDate;
    },
    slideDate(hours) {
      const { fromDate, toDate } = this.formatInputDates();
      const ms = hours * 60 * 60 * 1000;
      this.fromDate = new Date(fromDate.getTime() + ms);
      this.toDate = new Date(toDate.getTime() + ms);
    },
    validate() {
      this.onValidate(this.selectedKarnott).then(() => (this.selectedKarnott = []));
    },
    getFilteredSerialNumbers(text) {
      let updatedInputValue = text;
      const lc = text.toLowerCase();
      let serialNumbers = lc.split(",");
      if (serialNumbers.length === 1) {
        serialNumbers = lc.split(";");
      }
      this.filteredKarnott = this.devices.filter(d => {
        const serialnumberToLowerCase = d.serialnumber.toLowerCase();
        for (const i in serialNumbers) {
          if (serialnumberToLowerCase === serialNumbers[i]) {
            const alreadyAdded = this.selectedKarnott.find(
              ({ serialnumber }) => serialnumber.toLowerCase() === serialnumberToLowerCase
            );
            if (!alreadyAdded) {
              this.selectedKarnott.push(d);
            }
            const r = new RegExp(`${d.serialnumber},?`, "gi");
            updatedInputValue = updatedInputValue.replace(r, "");
            return false;
          }
          if (serialnumberToLowerCase.indexOf(serialNumbers[i]) >= 0) {
            return true;
          }
        }
        return false;
      });
      if (updatedInputValue !== text) {
        setTimeout(() => {
          // update input value if user copy/past list of serial number
          this.$refs.serialNumberInput.$el.querySelectorAll("input.input")[0].value = updatedInputValue;
        }, 10);
      }
    },
    getDevices() {
      const { apiClient } = this.$store.getters;
      this.isLoading = true;
      getDevices(apiClient)
        .then(({ data }) => {
          data.forEach(d => (d.device_instance_id = d.id));
          this.devices = data;
          this.isLoading = false;
        })
        .catch(e => {
          this.isLoading = false;
          this.setError(e);
        });
    },
    formatInputDates() {
      let { fromDate, toDate } = this;
      return formatInputDates(fromDate, toDate);
    },
    loadData() {
      const { fromDate, toDate } = this.formatInputDates();
      if (fromDate === null || toDate === null) {
        this.isLoadingEventPoints = false;
        return;
      }

      if (this.selectedKarnott.length === 0) {
        this.setError({
          message: "Veuillez sélectionner au moins un numéro de série"
        });
        return;
      }

      if (this.selectedKarnott.length > 5) {
        this.setError({
          message: "Veuillez sélectionner au maximum 5 numéros de série"
        });
        return;
      }

      this.isLoadingEventPoints = true;

      Promise.all(
        this.selectedKarnott.map(k => {
          return this.getEventPoints({
            id: k.id,
            filterBy: "occurred_at",
            params: {
              from: formatToRFC3339(fromDate),
              to: formatToRFC3339(toDate)
            }
          }).then(({ data }) => {
            data.forEach(d => {
              d.serialnumber = k.serialnumber;
            });
            return data;
          });
        })
      ).then(data => {
        this.resetMap(data.flat());
        this.isLoadingEventPoints = false;
        return data.flat();
      });
    },
    resetMap(dataFromApi) {
      this.mapData.forEach(md => {
        md.markers.forEach(m => m.setMap(null));
        md.polyline.setMap(null);
      });
      this.mapData = [];
      this.locationsGroupedBySerialNumber = new Map();
      dataFromApi.forEach(e => {
        if (!this.locationsGroupedBySerialNumber.has(e.serialnumber)) {
          this.locationsGroupedBySerialNumber.set(e.serialnumber, []);
          if (e.serialnumber) {
            const color = markerColors[this.locationsGroupedBySerialNumber.size - 1] || "#0e0e0e";
            this.mapData.push({
              color,
              serialNumber: e.serialnumber,
              markers: [],
              polyline: null,
              displayed: true
            });
          }
        }
        this.locationsGroupedBySerialNumber.get(e.serialnumber).push(e);
      });

      this.locationsGroupedBySerialNumber.forEach((points, serialNumber) => {
        let color = deviceSessionColor;
        if (this.mapData.length && this.mapData.find(md => md.serialNumber === serialNumber)) {
          color = this.mapData.find(md => md.serialNumber === serialNumber).color;
        }
        const { markers, path } = drawDataOnMap(
          points,
          this.map,
          {
            drawMarker: true,
            drawPolyline: true,
            color: color,
            opacity: 0.9,
            weight: 2.5,
            focusOn: false
          },
          true
        );
        if (this.mapData.length && this.mapData.find(md => md.serialNumber === serialNumber)) {
          this.mapData.find(md => md.serialNumber === serialNumber).markers = this.mapData
            .find(md => md.serialNumber === serialNumber)
            .markers.concat(markers);
          this.mapData.find(md => md.serialNumber === serialNumber).polyline = path;
        }
      });
      this.fitMapData();
    },
    toggleSerialNumber(serialNumber) {
      const toggleMapData = this.mapData.find(md => md.serialNumber === serialNumber);
      let mapContent = this.map;
      if (toggleMapData.displayed) {
        mapContent = null;
      }
      toggleMapData.markers.forEach(m => m.setMap(mapContent));
      toggleMapData.polyline.setMap(mapContent);
      toggleMapData.displayed = !toggleMapData.displayed;
    },
    fitMapData() {
      if (this.mapData.length) {
        const bounds = new google.maps.LatLngBounds();
        this.mapData
          .filter(md => md.displayed)
          .forEach(md => {
            md.polyline
              .getPath()
              .getArray()
              .forEach(i => {
                if (i === undefined) {
                  return;
                }
                bounds.extend(i);
              });
          });
        this.map.fitBounds(bounds);
      }
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      getEventPoints: GET_LOCATION_BY_DEVICE_INSTANCE_ID
    })
  },
  data() {
    const { fromDate, toDate } = getTwoDaysAgoInterval();
    return {
      selectedKarnott: [],
      filteredKarnott: [],
      devices: [],
      fromDate: fromDate,
      toDate: toDate,
      mapData: [],
      map: null,
      defaultInfoWindow: null,
      isLoadingEventPoints: false,
      locationsGroupedBySerialNumber: new Map(),
      goodColor: gpsColors.goodGPSReceptionColor,
      averageColor: gpsColors.averageGPSReceptionColor,
      badColor: gpsColors.badGPSReceptionColor
    };
  }
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: center;
}
#map-container {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: aliceblue;
}
#map {
  width: 90%;
  height: 600px;
}
#map-legend {
  width: 200px;
}
#map-legend > span {
  display: flex;
  align-items: center;
  margin: 10px;
}
#map-legend > span > svg {
  margin-right: 10px;
}
.button-field {
  padding: 31px 0 11px;
}
.legend-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.legend-line {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
</style>
