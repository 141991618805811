<template>
  <div>
    <div class="box">
      <form @keyup.enter="loadData" @submit.prevent="loadData">
        <b-field grouped>
          <b-field label="Depuis le">
            <b-datetimepicker v-model="fromDate" placeholder="Type or select a date..." icon="calendar-today" editable>
            </b-datetimepicker>
          </b-field>
          <b-field label="Jusqu'au">
            <b-datetimepicker v-model="toDate" placeholder="Type or select a date..." icon="calendar-today" editable>
            </b-datetimepicker>
          </b-field>
          <div class="field button-field">
            <p class="control">
              <button class="button is-success">Mettre à jour</button>
            </p>
          </div>
        </b-field>
        <b-field>
          <p class="control">
            <button
              @click.prevent="
                setTodayDate();
                loadData();
              "
              class="button is-outlined is-dark"
            >
              Aujourd'hui
            </button>
          </p>
          <p class="control">
            <button
              @click.prevent="
                setYesterdayDate();
                loadData();
              "
              class="button is-outlined is-dark"
            >
              Hier
            </button>
          </p>
          <p class="control">
            <button
              @click.prevent="
                setTwoDaysAgoDate();
                loadData();
              "
              class="button is-outlined is-dark"
            >
              12 dernières heures
            </button>
          </p>
          <p class="control">
            <button
              @click.prevent="
                slideDate(24);
                loadData();
              "
              class="button is-outlined is-dark"
            >
              +24 heures
            </button>
          </p>
          <p class="control">
            <button
              @click.prevent="
                slideDate(-24);
                loadData();
              "
              class="button is-outlined is-dark"
            >
              -24 heures
            </button>
          </p>
        </b-field>
      </form>
    </div>
    <div id="map-container">
      <b-loading :is-full-page="false" :active="isLoadingEventPoints"> </b-loading>
      <div id="map-legend" v-if="mapData.length">
        <span
          :style="`color:${darkerColor(r.color)};${r.displayed ? '' : 'text-decoration:line-through'};`"
          v-for="r in mapData"
          :key="r.color"
        >
          <svg width="20px" height="20px" @click="toggleSerialNumber(r.serialNumber)">
            <path d="M 0 0 H 20 V 20 H 0 Z" :fill="r.color" :stroke="darkerColor(r.color)" />
          </svg>
          {{ r.serialNumber }}
        </span>
      </div>
      <div id="map"></div>
    </div>
  </div>
</template>

<script>
/// Below line disables no-undef google Maps related warnings
/// (because Google Maps is loaded in a script tag rather than
/// in the bundle.).
/* global google */
import { SET_ERROR_ACTION } from "Stores/message";
import { GET_NETWORK_DATA_BY_DEVICE_INSTANCE_ID, GET_LOCATION_BY_DEVICE_INSTANCE_ID } from "Stores/device-data";
import { getCellTowers } from "Api/cellTowers";
import { mapActions } from "vuex";
import { dateToLocaleString, stringToDate, formatInputDates, formatToRFC3339 } from "Utils";
import { darkerColor, markerColors, deviceSessionColor } from "Utils/map";
import { adjustColor, getMNCColor, createSVGIcon, createSVGAntennaIcon } from "Utils/cellTowerMap";
const getTwoDaysAgoInterval = () => {
  let fromDate = new Date();
  let toDate = new Date();
  fromDate.setHours(fromDate.getHours() - 12);
  return {
    fromDate,
    toDate
  };
};
const getYesterdayInterval = () => {
  let fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - 1);
  fromDate.setHours(0);
  fromDate.setMinutes(0);

  let toDate = new Date();
  toDate.setDate(toDate.getDate() - 1);
  toDate.setHours(23);
  toDate.setMinutes(59);
  return {
    fromDate,
    toDate
  };
};
const getTodayInterval = () => {
  let fromDate = new Date();
  let toDate = new Date();
  fromDate.setHours(0);
  fromDate.setMinutes(0);
  return {
    fromDate,
    toDate
  };
};

const MANAGED_QUERY_PARAMS = [
  { field: "fromDate", fmt: v => stringToDate(v) },
  { field: "toDate", fmt: v => stringToDate(v) }
];

export default {
  props: ["devices"],
  computed: {},
  mounted() {
    this.syncFromQueryParams(MANAGED_QUERY_PARAMS);
    this.map = new google.maps.Map(document.getElementById("map"), {
      zoom: 3,
      mapTypeId: google.maps.MapTypeId.HYBRID,
      tilt: 0,
      rotateControl: false
    });
  },
  methods: {
    darkerColor(color) {
      return darkerColor(color);
    },
    setTodayDate() {
      const { fromDate, toDate } = getTodayInterval();
      this.fromDate = fromDate;
      this.toDate = toDate;
    },
    setYesterdayDate() {
      const { fromDate, toDate } = getYesterdayInterval();
      this.fromDate = fromDate;
      this.toDate = toDate;
    },
    setTwoDaysAgoDate() {
      const { fromDate, toDate } = getTwoDaysAgoInterval();
      this.fromDate = fromDate;
      this.toDate = toDate;
    },
    slideDate(hours) {
      const { fromDate, toDate } = this.formatInputDates();
      const ms = hours * 60 * 60 * 1000;
      this.fromDate = new Date(fromDate.getTime() + ms);
      this.toDate = new Date(toDate.getTime() + ms);
    },
    formatInputDates() {
      let { fromDate, toDate } = this;
      return formatInputDates(fromDate, toDate);
    },
    loadData() {
      const { fromDate, toDate } = this.formatInputDates();
      if (fromDate === null || toDate === null) {
        return;
      }

      this.updateUrl();
      this.isLoadingEventPoints = true;
      Promise.all(
        this.devices.map(k => {
          return this.getNetworkData({
            id: k.id,
            filterBy: "occurred_at",
            params: {
              from: formatToRFC3339(fromDate),
              to: formatToRFC3339(toDate)
            }
          }).then(({ data }) => {
            data.forEach(d => {
              d.serialnumber = k.serialnumber;
            });
            // get position for each cell tower
            const cellTowers = data.reduce((acc, curr) => {
              acc.push({
                mcc: curr.mcc,
                mnc: curr.mnc,
                lac: curr.lac,
                cell_id: curr.cell_id
              });
              return acc;
            }, []);
            const { apiClient } = this.$store.getters;
            if (!cellTowers.length) {
              return data;
            }
            return getCellTowers(apiClient)(cellTowers).then(({ data: respCellTowers }) => {
              return data.map(d => {
                const cellTower = respCellTowers?.find(
                  ct => ct.mcc === d.mcc && ct.mnc === d.mnc && ct.lac === d.lac && ct.cell_id === d.cell_id
                );
                if (cellTower) {
                  d.cell_tower_latitude = cellTower.latitude;
                  d.cell_tower_longitude = cellTower.longitude;
                }
                return d;
              });
            });
          });
        })
      ).then(data => {
        this.resetMap(data.flat());
        this.isLoadingEventPoints = false;
        return data.flat();
      });
    },

    resetMap(dataFromApi) {
      this.mapData.forEach(md => {
        md.markers.forEach(m => m.setMap(null));
      });
      this.mapData = [];
      this.locationsGroupedBySerialNumber = new Map();
      dataFromApi.forEach(e => {
        if (!this.locationsGroupedBySerialNumber.has(e.serialnumber)) {
          this.locationsGroupedBySerialNumber.set(e.serialnumber, []);
          if (e.serialnumber) {
            const color = markerColors[this.locationsGroupedBySerialNumber.size - 1] || "#0e0e0e";
            this.mapData.push({
              color,
              serialNumber: e.serialnumber,
              markers: [],
              polyline: null,
              displayed: true
            });
          }
        }
        this.locationsGroupedBySerialNumber.get(e.serialnumber).push(e);
      });

      this.locationsGroupedBySerialNumber.forEach((data, serialNumber) => {
        let strokeColor = deviceSessionColor;
        if (this.mapData.length && this.mapData.find(md => md.serialNumber === serialNumber)) {
          strokeColor = this.mapData.find(md => md.serialNumber === serialNumber).color;
        }
        // add on map all data.cell_tower_latitude, data.cell_tower_longitude

        for (let i = 0; i < data.length; i++) {
          const {
            cell_tower_latitude,
            cell_tower_longitude,
            device_latitude,
            device_longitude,
            mcc,
            mnc,
            lac,
            cell_id,
            signal_quality,
            occurred_at,
            radio_access_technology
          } = data[i];
          if (device_latitude && device_longitude) {
            // set a custom color for mcc
            // mnc 1 = orange
            // mnc 10 = sfr
            // mnc 20 = bouygues

            let mncColor = getMNCColor(mnc);

            let rat;
            let is4G = false;
            switch (radio_access_technology) {
              case 3:
                rat = "2G";
                break;
              case 7:
                rat = "4G";
                is4G = true;
                break;
              default:
                rat = "N/A";
            }

            const adjustedColor = is4G ? adjustColor(mncColor, 1.0) : adjustColor(mncColor, 1.4);

            const marker = new google.maps.Marker({
              position: { lat: device_latitude, lng: device_longitude },
              map: this.map,
              icon: createSVGIcon(google, rat, adjustedColor, strokeColor),
              title: `${occurred_at} - ${signal_quality}% - mcc : ${mcc} - mnc : ${mnc} - lac : ${lac} - cell_id : ${cell_id}`
            });
            this.mapData.find(md => md.serialNumber === serialNumber).markers.push(marker);
            if (cell_tower_latitude && cell_tower_longitude) {
              const markerAntenna = new google.maps.Marker({
                position: { lat: cell_tower_latitude, lng: cell_tower_longitude },
                map: this.map,
                icon: createSVGAntennaIcon(google, adjustedColor),
                title: `mcc : ${mcc} - mnc : ${mnc} - lac : ${lac} - cell_id : ${cell_id}`
              });
              this.mapData.find(md => md.serialNumber === serialNumber).markers.push(markerAntenna);
              const line = new google.maps.Polyline({
                path: [
                  { lat: device_latitude, lng: device_longitude },
                  { lat: cell_tower_latitude, lng: cell_tower_longitude }
                ],
                geodesic: true,
                strokeColor: adjustedColor,
                strokeOpacity: 0,
                icons: [
                  {
                    icon: {
                      path: "M 0,-1 0,1",
                      strokeOpacity: 0.8,
                      scale: 2
                    },
                    offset: "0",
                    repeat: "20px"
                  }
                ]
              });
              line.setMap(this.map);
              this.mapData.find(md => md.serialNumber === serialNumber).markers.push(line);
            }
          }
        }
      });
      this.fitMapData();
    },
    toggleSerialNumber(serialNumber) {
      const toggleMapData = this.mapData.find(md => md.serialNumber === serialNumber);
      let mapContent = this.map;
      if (toggleMapData.displayed) {
        mapContent = null;
      }
      toggleMapData.markers.forEach(m => m.setMap(mapContent));
      toggleMapData.displayed = !toggleMapData.displayed;
      this.fitMapData();
    },
    fitMapData() {
      if (this.mapData.length) {
        const bounds = new google.maps.LatLngBounds();
        this.mapData.forEach(md => {
          md.markers.forEach(m => {
            if (m instanceof google.maps.Marker) {
              bounds.extend(m.getPosition());
            }
          });
        });

        this.map.fitBounds(bounds);
      }
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      getNetworkData: GET_NETWORK_DATA_BY_DEVICE_INSTANCE_ID,
      getEventPoints: GET_LOCATION_BY_DEVICE_INSTANCE_ID
    }),
    updateUrl() {
      const query = Object.assign({}, this.$route.query);
      MANAGED_QUERY_PARAMS.map(prop => (prop.field ? prop.field : prop)).forEach(prop => {
        const value = prop === "fromDate" || prop === "toDate" ? dateToLocaleString(this[prop]) : this[prop];
        return (query[prop] = value);
      });
    },
    syncFromQueryParams(props) {
      props.forEach(prop => {
        const field = prop.field ? prop.field : prop;
        const value = this.$route.query[field];

        if (value) {
          this[field] = prop.fmt ? prop.fmt(value) : value;
        }
      });
    }
  },
  data() {
    const { fromDate, toDate } = getTwoDaysAgoInterval();
    return {
      fromDate: fromDate,
      toDate: toDate,
      mapData: [],
      map: null,
      defaultInfoWindow: null,
      isLoadingEventPoints: false,
      locationsGroupedBySerialNumber: new Map()
    };
  }
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: center;
}
#map-container {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 20px;
}
#map {
  width: 90%;
  height: 600px;
}
#map-legend {
  width: 200px;
}
#map-legend > span {
  display: flex;
  align-items: center;
  margin: 10px;
}
#map-legend > span > svg {
  margin-right: 10px;
}
.button-field {
  padding: 31px 0 11px;
}
.legend-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.legend-line {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
</style>
